<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <h3 class="display-2">Buka Input SKP PLT</h3>
          </template>
          <v-toolbar flat>
            <v-col cols="3">
              <v-select v-model="selectSKPD" item-text="v" item-value="k" :items="skpd" label="SKPD" hide-details></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field  v-model="search" label="Cari" hide-details append-icon="mdi-magnify"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :loading="loading"
            :footer-props="{'items-per-page-options':[5,10,15]}"
          >
            <template v-slot:item.nip="{ item }">
              <div v-html="item.nip"></div>
            </template>
            <template v-slot:item.id_plt="{ item }">
              PLT-{{item.jabatan_id}}
            </template>
            <template v-slot:item.job_value="{ item }">
              <div v-html="item.job_value"></div>
            </template>
            <template v-slot:item.status="{ item }">
              <v-btn x-small :class="{ 'red--text':item.status.toLowerCase() === 'jabatan kosong','blue--text':item.status.toLowerCase() !== 'jabatan kosong' }">{{item.status}}</v-btn>

            </template>
            <template v-slot:item.ekspektasi_perilaku="{ item }">
              <v-btn v-if="!item.ekspektasi_tahunan_terbuka" @click="bukaEkspektasiPerilaku(item, 'tahun')" small class="green ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Input Ekspektasi Perilaku Tahunan</v-btn>
              <v-btn v-if="item.ekspektasi_tahunan_terbuka" @click="tutupEkspektasiPerilaku(item, 'tahun')" small class="grey ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Input Ekspektasi Perilaku Tahunan</v-btn>
              <br>
              <br>
              <v-btn v-if="!item.ekspektasi_triwulan_1_terbuka" @click="bukaEkspektasiPerilaku(item, 1)" small class="blue darken-1 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Input Ekspektasi Perilaku TW 1</v-btn>
              <v-btn v-if="item.ekspektasi_triwulan_1_terbuka" @click="tutupEkspektasiPerilaku(item, 1)" small class="blue-grey darken-1 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Input Ekspektasi Perilaku TW 1</v-btn>
              <v-btn v-if="!item.ekspektasi_triwulan_2_terbuka" @click="bukaEkspektasiPerilaku(item, 2)" small class="blue darken-2 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Input Ekspektasi Perilaku TW 2</v-btn>
              <v-btn v-if="item.ekspektasi_triwulan_2_terbuka" @click="tutupEkspektasiPerilaku(item, 2)" small class="blue-grey darken-2 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Input Ekspektasi Perilaku TW 2</v-btn>
              <v-btn v-if="!item.ekspektasi_triwulan_3_terbuka" @click="bukaEkspektasiPerilaku(item, 3)" small class="blue darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Input Ekspektasi Perilaku TW 3</v-btn>
              <v-btn v-if="item.ekspektasi_triwulan_3_terbuka" @click="tutupEkspektasiPerilaku(item, 3)" small class="blue-grey darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Input Ekspektasi Perilaku TW 3</v-btn>
              <v-btn v-if="!item.ekspektasi_triwulan_4_terbuka" @click="bukaEkspektasiPerilaku(item, 4)" small class="blue darken-4 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Input Ekspektasi Perilaku TW 4</v-btn>
              <v-btn v-if="item.ekspektasi_triwulan_4_terbuka" @click="tutupEkspektasiPerilaku(item, 4)" small class="blue-grey darken-4 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Input Ekspektasi Perilaku TW 4</v-btn>
            </template>
            <template v-slot:item.umpan_balik="{ item }">
              <v-btn v-if="!item.umpan_balik_tahunan_terbuka" @click="bukaUmpanBalik(item, 'tahun')" small class="green ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Input Umpan Balik Tahunan</v-btn>
              <v-btn v-if="item.umpan_balik_tahunan_terbuka" @click="tutupUmpanBalik(item, 'tahun')" small class="grey ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Input Umpan Balik Tahunan</v-btn>
              <br>
              <br>
              <v-btn v-if="!item.umpan_balik_triwulan_1_terbuka" @click="bukaUmpanBalik(item, 1)" small class="blue darken-1 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Umpan Balik TW 1</v-btn>
              <v-btn v-if="item.umpan_balik_triwulan_1_terbuka" @click="tutupUmpanBalik(item, 1)" small class="blue-grey darken-1 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Umpan Balik TW 1</v-btn>
              <v-btn v-if="!item.umpan_balik_triwulan_2_terbuka" @click="bukaUmpanBalik(item, 2)" small class="blue darken-2 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Umpan Balik TW 2</v-btn>
              <v-btn v-if="item.umpan_balik_triwulan_2_terbuka" @click="tutupUmpanBalik(item, 2)" small class="blue-grey darken-2 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Umpan Balik TW 2</v-btn>
              <v-btn v-if="!item.umpan_balik_triwulan_3_terbuka" @click="bukaUmpanBalik(item, 3)" small class="blue darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Umpan Balik TW 3</v-btn>
              <v-btn v-if="item.umpan_balik_triwulan_3_terbuka" @click="tutupUmpanBalik(item, 3)" small class="blue-grey darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Umpan Balik TW 3</v-btn>
              <v-btn v-if="!item.umpan_balik_triwulan_4_terbuka" @click="bukaUmpanBalik(item, 4)" small class="blue darken-4 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Umpan Balik TW 4</v-btn>
              <v-btn v-if="item.umpan_balik_triwulan_4_terbuka" @click="tutupUmpanBalik(item, 4)" small class="blue-grey darken-4 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Umpan Balik TW 4</v-btn>
            </template>
            <template v-slot:item.reset_umpan_balik="{ item }">
              <v-btn @click="resetUmpanBalik(item, 'tahun')" small class="green ma-2" dark><v-icon>mdi-check-circle</v-icon> Reset Nilai Umpan Balik Tahunan</v-btn>
              <br>
              <br>
              <v-btn @click="resetUmpanBalik(item, 1)" small class="blue darken-1 ma-2" dark><v-icon>mdi-check-circle</v-icon> Reset Nilai Umpan Balik TW 1</v-btn>
              <v-btn @click="resetUmpanBalik(item, 2)" small class="blue darken-2 ma-2" dark><v-icon>mdi-check-circle</v-icon> Reset Nilai Umpan Balik TW 2</v-btn>
              <v-btn @click="resetUmpanBalik(item, 3)" small class="blue darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Reset Nilai Umpan Balik TW 3</v-btn>
              <v-btn @click="resetUmpanBalik(item, 4)" small class="blue darken-4 ma-2" dark><v-icon>mdi-check-circle</v-icon> Reset Nilai Umpan Balik TW 4</v-btn>
            </template>
            <template v-slot:item.refresh_penilaian="{ item }">
              <v-btn @click="refreshPenilaian(item, 'tahunan')" small :loading="loadingRefreshPenilaian.tahunan == 'PLT-'+item.jabatan_id" class="lime darken-2 ma-2" dark><v-icon>mdi-sync</v-icon>&nbsp; Refresh Penilaian SKP Tahunan</v-btn>
              <v-btn @click="refreshPenilaian(item, 1)" small :loading="loadingRefreshPenilaian.tw1 == 'PLT-'+item.jabatan_id" class="teal darken-1 ma-2" dark><v-icon>mdi-sync</v-icon>&nbsp; Refresh Penilaian SKP Triwulan 1</v-btn>
              <v-btn @click="refreshPenilaian(item, 2)" small :loading="loadingRefreshPenilaian.tw2 == 'PLT-'+item.jabatan_id" class="teal darken-2 ma-2" dark><v-icon>mdi-sync</v-icon>&nbsp; Refresh Penilaian SKP Triwulan 2</v-btn>
              <v-btn @click="refreshPenilaian(item, 3)" small :loading="loadingRefreshPenilaian.tw3 == 'PLT-'+item.jabatan_id" class="teal darken-3 ma-2" dark><v-icon>mdi-sync</v-icon>&nbsp; Refresh Penilaian SKP Triwulan 3</v-btn>
              <v-btn @click="refreshPenilaian(item, 4)" small :loading="loadingRefreshPenilaian.tw4 == 'PLT-'+item.jabatan_id" class="teal darken-4 ma-2" dark><v-icon>mdi-sync</v-icon>&nbsp; Refresh Penilaian SKP Triwulan 4</v-btn>
            </template>
            <template v-slot:item.fix_data_tutup_skp_tahunan="{ item }">
              <v-btn @click="fixDataTutupSKP(item)" small :loading="loadingProcess.data_tutup_skp == 'PLT-'+item.jabatan_id" class="yellow accent-4 ma-2 black--text" dark><v-icon>mdi-sync</v-icon>&nbsp; Perbaiki Data Tutup SKP Tahunan</v-btn>
            </template>
            <template v-slot:item.aksi="{item}">
              <div class="d-flex flex-column">
                <!-- <v-btn small class="ma-2"><v-icon>mdi-magnify</v-icon> Detail</v-btn> -->
                <v-btn v-if="!item.terbuka_tahunan" @click="bukaInputSkp(item, 'tahunan')" small class="green ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Input SKP Tahunan</v-btn>
                <v-btn v-if="item.terbuka_tahunan" @click="tutupInputSkp(item, 'tahunan')" small class="grey ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Input SKP Tahunan</v-btn>
                <v-btn v-if="!item.terbuka_triwulan" @click="bukaInputSkp(item, 'triwulan')" small class="green darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Input SKP Triwulan</v-btn>
                <v-btn v-if="item.terbuka_triwulan" @click="tutupInputSkp(item, 'triwulan')" small class="grey darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Input SKP Triwulan</v-btn>
                <br>
                <v-btn v-if="!item.lapor_triwulan_1_terbuka" @click="bukaLaporSkpTriwulan(item, 1)" small class="blue darken-1 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Lapor SKP Triwulan 1</v-btn>
                <v-btn v-if="item.lapor_triwulan_1_terbuka" @click="tutupLaporSkpTriwulan(item, 1)" small class="blue-grey darken-1 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Lapor SKP Triwulan 1</v-btn>
                <v-btn v-if="!item.lapor_triwulan_2_terbuka" @click="bukaLaporSkpTriwulan(item, 2)" small class="blue darken-2 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Lapor SKP Triwulan 2</v-btn>
                <v-btn v-if="item.lapor_triwulan_2_terbuka" @click="tutupLaporSkpTriwulan(item, 2)" small class="blue-grey darken-2 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Lapor SKP Triwulan 2</v-btn>
                <v-btn v-if="!item.lapor_triwulan_3_terbuka" @click="bukaLaporSkpTriwulan(item, 3)" small class="blue darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Lapor SKP Triwulan 3</v-btn>
                <v-btn v-if="item.lapor_triwulan_3_terbuka" @click="tutupLaporSkpTriwulan(item, 3)" small class="blue-grey darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Lapor SKP Triwulan 3</v-btn>
                <v-btn v-if="!item.lapor_triwulan_4_terbuka" @click="bukaLaporSkpTriwulan(item, 4)" small class="blue darken-4 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Lapor SKP Triwulan 4</v-btn>
                <v-btn v-if="item.lapor_triwulan_4_terbuka" @click="tutupLaporSkpTriwulan(item, 4)" small class="blue-grey darken-4 ma-2" dark><v-icon>mdi-check-circle</v-icon> Tutup Lapor SKP Triwulan 4</v-btn>
                <br>
                <div class="d-flex flex-column" v-if="item.jenis_jabatan == 'S'">
                  <v-btn v-if="!item.validasi_triwulan_1_terbuka" @click="bukaValidasiSkpTriwulan(item, 1)" small class="amber darken-1 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Validasi SKP Triwulan 1</v-btn>
                  <v-btn v-if="item.validasi_triwulan_1_terbuka" @click="tutupValidasiSkpTriwulan(item, 1)" small class="brown darken-1 ma-2" dark><v-icon>mdi-close-circle</v-icon> Tutup Validasi SKP Triwulan 1</v-btn>
                  <v-btn v-if="!item.validasi_triwulan_2_terbuka" @click="bukaValidasiSkpTriwulan(item, 2)" small class="amber darken-2 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Validasi SKP Triwulan 2</v-btn>
                  <v-btn v-if="item.validasi_triwulan_2_terbuka" @click="tutupValidasiSkpTriwulan(item, 2)" small class="brown darken-2 ma-2" dark><v-icon>mdi-close-circle</v-icon> Tutup Validasi SKP Triwulan 2</v-btn>
                  <v-btn v-if="!item.validasi_triwulan_3_terbuka" @click="bukaValidasiSkpTriwulan(item, 3)" small class="amber darken-3 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Validasi SKP Triwulan 3</v-btn>
                  <v-btn v-if="item.validasi_triwulan_3_terbuka" @click="tutupValidasiSkpTriwulan(item, 3)" small class="brown darken-3 ma-2" dark><v-icon>mdi-close-circle</v-icon> Tutup Validasi SKP Triwulan 3</v-btn>
                  <v-btn v-if="!item.validasi_triwulan_4_terbuka" @click="bukaValidasiSkpTriwulan(item, 4)" small class="amber darken-4 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Validasi SKP Triwulan 4</v-btn>
                  <v-btn v-if="item.validasi_triwulan_4_terbuka" @click="tutupValidasiSkpTriwulan(item, 4)" small class="brown darken-4 ma-2" dark><v-icon>mdi-close-circle</v-icon> Tutup Validasi SKP Triwulan 4</v-btn>
                  <v-btn v-if="!item.validasi_tahunan_terbuka" @click="bukaValidasiSkpTriwulan(item, 'tahun')" small class="amber darken-5 ma-2" dark><v-icon>mdi-check-circle</v-icon> Buka Validasi SKP Tahunan</v-btn>
                  <v-btn v-if="item.validasi_tahunan_terbuka" @click="tutupValidasiSkpTriwulan(item, 'tahun')" small class="brown darken-5 ma-2" dark><v-icon>mdi-close-circle</v-icon> Tutup Validasi SKP Tahunan</v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import SKPService from '@/services/SKPService'
import OtherService from '@/services/OtherService'
import axios from 'axios'
import _g from '../../global'
import store from '@/store'

export default {
  data(){
    return{
      filterPegawai:true,
      forceUpdate:false,
      loading:false,
      detailData:{},
      selectSKPD:null,
      search:'',
      skpd:[],
      page:1,
      rowPerPage:15,
      totalKeseluruhan:0,

      headers:[
        {text:'No', value:'nomer', sortable:false, class:'header-index-profile'},
        {text:'Jabatan', value:'jabatan_nama', sortable:false, class:'header-index-profile'},
        {text:'ID PLT', value:'id_plt', sortable:false, class:'header-index-profile'},
        {text:'Status', value:'status', sortable:false, class:'header-index-profile'},
        {text:'Ekspektasi Perilaku', value:'ekspektasi_perilaku', sortable:false, class:'header-index-profile'},
        {text:'Umpan Balik', value:'umpan_balik', sortable:false, class:'header-index-profile'},
        {text:'Reset Umpan Balik', value:'reset_umpan_balik', sortable:false, class:'header-index-profile'},
        {text:'Refresh Penilaian', value:'refresh_penilaian', sortable:false, class:'header-index-profile'},
        {text:'Tutup SKP Tahunan', value:'fix_data_tutup_skp_tahunan', sortable:false, class:'header-index-profile'},
        {text:'SKP', value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      loadingRefreshPenilaian:{},
      loadingProcess:{}
    }
  },

  watch:{
    page(val){
      // untuk memastikan tidak reload halaman awal
      if (val > 1){
        this.loading = true
        this.tableValue.page = val
        this.updateTable(this.tableValue)
      }
    }
  },

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)

    OtherService.getSKPD().then(response => {
      let data = []
      _.each(response.data.data, function(value, key){
        data.push({k:key, v: value})
      })
      this.skpd = data
    })
  },

  mounted(){
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD], val => {
      this.page = 1

      this.updateTable({itemsPerPage:this.rowPerPage, skpd:this.selectSKPD, search:this.search, page:this.page})
    })
  },

  computed:{
    filterSemua:{
      get:function(){
        return !this.filterPegawai
      },
      set:function(val){
        this.filterPegawai = !val
      }
    },
    currentYear(){
      return store.state.user.current.year
    },
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.tableValue = val? val:this.tableValue

      if(this.tableValue){
        this.loading=true
        SKPService.bukaInputSKPGetDataPLT({
          page:this.tableValue.page,
          perpage:this.tableValue.itemsPerPage,
          search:this.tableValue.search,
          skpd: this.tableValue.skpd
        }).then(response=>{
          this.totalKeseluruhan = response.data.total_keseluruhan
          this.rowPerPage = response.data.row_per_page
          this.responseData = response.data
          this.items = response.data.data
          this.loading=false
        })
      }
    },

    bukaInputSkp(data, jenis){
      var url = jenis == 'tahunan' ? "v1/buka-skp-tahunan/buka" : "v1/buka-skp-triwulan/buka"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: 'PLT-'+data.jabatan_id, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    tutupInputSkp(data, jenis){
      var url = jenis == 'tahunan' ? "v1/buka-skp-tahunan/tutup" : "v1/buka-skp-triwulan/tutup"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: 'PLT-'+data.jabatan_id, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    bukaLaporSkpTriwulan(data, tw){
      var url = "v1/buka-skp-triwulan/bukaLapor"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    tutupLaporSkpTriwulan(data, tw){
      var url = "v1/buka-skp-triwulan/tutupLapor"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    bukaValidasiSkpTriwulan(data, tw){
      var url = "v1/buka-skp-triwulan/bukaValidasi"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    tutupValidasiSkpTriwulan(data, tw){
      var url = "v1/buka-skp-triwulan/tutupValidasi"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    bukaEkspektasiPerilaku(data, tw){
      axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/buka-ekspektasi/buka"),
        {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}
      ).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    tutupEkspektasiPerilaku(data, tw){
      axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/buka-ekspektasi/tutup"),
        {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}
      ).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    bukaUmpanBalik(data, tw){
      axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/buka-umpan-balik/buka"),
        {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}
      ).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    tutupUmpanBalik(data, tw){
      axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/buka-umpan-balik/tutup"),
      {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}
      ).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    resetUmpanBalik(data, tw){
      axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/reset-umpan-balik"),
      {nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    refreshPenilaian(data, tw){
      this.loadingRefreshPenilaian = {}
      if(tw == 'tahunan'){
        this.loadingRefreshPenilaian.tahunan = 'PLT-'+data.jabatan_id
      }else{
        switch (tw) {
          case 1:
            this.loadingRefreshPenilaian.tw1 = 'PLT-'+data.jabatan_id
            break;
          case 2:
            this.loadingRefreshPenilaian.tw2 = 'PLT-'+data.jabatan_id
            break;
          case 3:
            this.loadingRefreshPenilaian.tw3 = 'PLT-'+data.jabatan_id
            break;
          case 4:
            this.loadingRefreshPenilaian.tw4 = 'PLT-'+data.jabatan_id
            break;
        }
      }
      var url = "v1/buka-skp/refreshPenilaianSKPPermenpan6"
      let current = store.state.user.current
      let user = store.state.user
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      axios.post(url_, {username:current.username, nip: 'PLT-'+data.jabatan_id, triwulan: tw, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        if(tw == 'tahunan'){
          this.loadingRefreshPenilaian.tahunan = -1
        }else{
          switch (tw) {
            case 1:
              this.loadingRefreshPenilaian.tw1 = -1
              break;
            case 2:
              this.loadingRefreshPenilaian.tw2 = -1
              break;
            case 3:
              this.loadingRefreshPenilaian.tw3 = -1
              break;
            case 4:
              this.loadingRefreshPenilaian.tw4 = -1
              break;
          }
        }
        this.updateTable()
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    fixDataTutupSKP(data){
      this.loadingProcess = {}
      this.loadingProcess.data_tutup_skp = 'PLT-'+data.jabatan_id
      axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/fix-data-tutup-skp-tahunan"),
      {nip: 'PLT-'+data.jabatan_id, api: 1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
        this.loadingProcess.data_tutup_skp = -1
      }).finally(()=>{
        this.loadingProcess.data_tutup_skp = -1
      }).catch(err => {
        var error = err.response.data.message
        this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
